footer {
    box-shadow: 0px -4px 6px -4px rgb(0, 0, 0, 0.25);
    padding-top: 40px;
    padding-bottom: 40px;
}

footer div {
    height: 100%;
}

footer h3 {
    font-weight: 700;
}

.hazelcast-korea-contact {
    margin-top: 1rem;
}
.hazelcast-korea-contact p {
    margin: 0;
}

footer h5 {
    margin-bottom: 20px;
    color: #00ADBB;
    font-weight: 600;
}

.useful-links a, .get-hazelcast a {
    color: black;
    text-decoration: none;
}

.useful-links span, .get-hazelcast span {
    color: #00ADBB;
}

.useful-links a:hover, .get-hazelcast p:hover {
    color: #00ADBB;
    font-weight: 700;
    cursor: pointer;
}

.sns span {
    color: #00ADBB;
    font-size: 48px;
    line-height: 0%;
}
.naver-icon {
    margin-left: 3px;
}

.instagram-icon:hover {
    color: #C91268;
    cursor: pointer;
}
.facebook-icon:hover {
    color: #0c1580;
    cursor: pointer;
}