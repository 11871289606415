.page-title {
    margin-bottom: 2.7rem;
}
@media (max-width: 576px) {
    .page-title {
        margin-bottom: 1rem;
    }
}

.page-title p {
    word-break: keep-all;
}
.page-title .title1 {
    margin-bottom: 0;
}