.resource-section-tit {
    text-align: center;
}
.resourceCard-body title1 {
    text-align: center;
}

.custom-tab-menu {
    display: flex;
    justify-content: center;
    background-color: #F0F6FF;
    padding: 10px;
    border-radius: 45px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: auto;
    width: fit-content;
    margin-bottom: 35px;
}

.custom-tabPanel .card {
    padding: 0 15px;
}

@media (max-width: 768px) {
    .custom-tabPanel .d-flex {
        margin-bottom: 20px;
    }
}

@media (max-width: 576px) {
    .custom-tab-menu .nav-item a {
        padding: 3px 5px;
    }

    .resourceSection-body .nav {
        height: auto;
    }

    .custom-tabPanel .card {
        padding: 0 8px;
    }

}

.custom-tab-menu .nav-link {
    color: #000;
    background-color: transparent;
    border: 2px solid transparent;
    border-radius: 45px;
}

.custom-tab-menu .nav-link:hover,
.custom-tab-menu .nav-link.active {
    border: 2px solid #10A4B3;
    border-radius: 45px;
}

.custom-tab-menu .nav-link:hover {
    color: #10A4B3;
    transition: ease;
}

.custom-tab-menu .nav-link.active {
    color: white;
    background-color: #10A4B3;
}

.custom-tab-menu .nav-item {
    margin: 0 2px;
    /* min-width: ?; */
}

.resourceSection-body .nav-tabs {
    border: 1px solid white;
}

.resourceSection-body .nav-link,
.resourceSection-body .nav-link:hover {
    transition: border-radius 0.3s ease;
}

.resourceSection-body .tab-panel card {
    height: 100%;
}