.contact-section {
    background-color: #e0e0e0;
}

.contact-section #map {
    width: 100%;
    height: 400px;
}

.contact-text:not(:last-child) {
    margin-bottom: 2rem;
}

.contact-text svg {
    margin-right: 5px;
}

@media (max-width: 576px) {
    .contact-section #map {
        height: 300px;
    }
}