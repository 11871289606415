.ourStory-section {
    /* background-image: url('../assets/images/DALL·E\ 2024-03-25\ 10.34.04.png'); */
    background-image: url('../assets/images/DALL·E-2024-03-25-10.34.04.webp');
    background-size: cover;
    background-position: top center;
}
.ourStory-section-tit {
    margin-bottom: 3.5rem;
}
.ourStory-section-subTit {
    margin-bottom: 1rem;
}
.ourStory-section-text {
    margin-bottom: 3.5rem;
}

.ourStory {
    
}
.ourStory-tit {
    margin-bottom: 20px;
}

@media (max-width: 768px) {
    .ourStory-section-tit {
        margin-bottom: 1.5rem;
    }
}