.instantActionSection-tit {
    margin-bottom: 30px;
}

.instantActionSection-text-container {
    margin-bottom: 30px;
}
.instantActionSection-text-container p:not(:last-child) {
    margin-bottom: 8px;
}

.instantAction-img-container {
    text-align: center;
}

.instantAction-img-container img {
    width: 350px;
    object-fit: contain;
}

@media (max-width: 992px) {
    .instantActionSection-tit {
        font-size: 30px;
    }

    .instantActionSetion-btn {
        padding: 10px 10px;
    }
}

@media (max-width: 576px) {
    .instantActionSection-tit {
        margin-bottom: 20px;
    }
}