.my-breadcrumb {
  margin-top: 3.7rem;
}

.my-breadcrumb ol li,
.my-breadcrumb span {
  margin-right: 10px;
}

.breadcrumb-item a,
.breadcrumb-item.active,
.breadcrumb span {
  color: white;
  text-decoration: none;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  color: #fff;
}

.wtfisistnotworking {
  font-size: 50px;
  background: linear-gradient(to right, #10a4b3 0%, #051a27 130%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  /* Fallback for browsers that do not support background-clip: text */
  -webkit-text-fill-color: transparent;
  /* Webkit browsers like Safari */
  display: inline-block;
}
