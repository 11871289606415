.useCase-card-container {
    position: relative;
    min-height: 400px;
}
.useCase-card {
    position: absolute;
    border-radius: 20px;
    min-height: 400px;
    width: 100%;
    z-index: 1;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}
.card-expanded {
    height: auto;
    transform: scale(1.05);
    transition: transform 0.3s ease-in-out, z-index 0s ease;
    z-index: 10;
}

.useCase-card-body {
    padding: 40px 30px;
}

.useCase-card-body .title5 {
    margin-bottom: 15px;
}

.text-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.card-expanded .text-clamp {
    display: block;
    -webkit-line-clamp: none;
    padding-bottom: 40px;
}
.useCase-card-link {
    color: black;
    text-decoration: none;
    position: absolute;
    bottom: 40px;
}
.useCase-card-link:hover {
    color: #10A4B3;
}

@media (max-width: 1200px) {
    .useCase-card-container {
        min-height: 300px;
    }
    .useCase-card {
        min-height: 300px;
    }
}
@media (max-width: 992px) {
    .text-clamp {
        -webkit-line-clamp: 5;
    }
}
@media (max-width: 576px) {
    .useCase-card-container {
        min-height: 200px;
    }
    .useCase-card {
        min-height: 200px;
    }
    .text-clamp {
        -webkit-line-clamp: 5;
    }
    .useCase-card-link {
        bottom: 20px;
    }
}