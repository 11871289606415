.card-description {
    margin-bottom: 20px;
}
.sub-title{
    margin-bottom: 10px;
}

.list-detail {
    padding-left: 40px;
}

/* 화면 크기가 768px 이하일 때 적용할 스타일 */
@media (max-width: 768px) {
    .card-description {
        margin-top: 10px;
    }
}