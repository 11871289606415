.myAccordion {
    border-radius: 20px;
    overflow: hidden;
}
.myAccordion .accordion-button {
    background-color: #ececec;
}
.myAccordion .accordion-button:focus {
    outline: 0;
    box-shadow: none;
}
.myAccordion .accordion-button:not(.collapsed) {
    color: #10A4B3;
    background-color: #e3edfc;
}
.myAccordion .accordion-item {
    background-color: #ffffff;
    border-bottom: 2px solid #d3d3d3;
}
.myAccordion .accordion-button::after {
    display: none;
}

.myAccordion-tit-container {
    /* padding: 15px 0; */
}
.myAccordion-tit-container .accordion-button {
    padding: 30px 50px;
}
.myAccordion-text-container {
    padding: 30px 50px;
}
.myAccordion-text-container .body2 + .body2 {
    margin-top: 15px;
}
.myAccordion-list {
    padding-left: 10px;
}
@media (max-width: 992px) {
    .myAccordion-tit-container .accordion-button,
    .myAccordion-text-container {
        padding: 30px 20px;
    }
}
@media (max-width: 300px) {
    .myAccordion-tit-container .accordion-button,
    .myAccordion-text-container {
        padding: 25px 12px;
    }
}