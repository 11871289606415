.baseCard {
    margin-bottom: 3.7rem;
    border-radius: 20px;
    padding: 56px 75px;
    border: none;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.baseCard-ice {
    background-color: rgba(240, 246, 255, 0.5);
}

.baseCard-dusk {
    background-color: rgba(6, 25, 39, 0.8);
}

.baseCard-white {
    background-color: #fff;
}

/* 화면 크기가 768px 이하일 때 적용할 스타일 */
@media (max-width: 768px) {
    .baseCard {
        padding: 56px 30px;
    }
}

@media (max-width: 576px) {
    .baseCard {
        padding: 26px 10px;
    }

    .baseCard .card-body {
        padding: 16px 8px;
    }
}

/* 다크 모드 */
/* @media (prefers-color-scheme: dark) {
    .baseCard-ice {
        background-color: rgb(45, 62, 70);
    }

    .baseCard-dusk {
        background-color: rgb(17, 34, 46);
    }
} */