.typography {
    margin: 0;
    padding: 0;
    word-break: keep-all;
}

.title1 {
    font-size: 56px;
    font-weight: 800;
    margin-bottom: 30px;
}

.title2 {
    font-size: 44px;
    font-weight: 800;
    margin-bottom: 30px;
}

.title3 {
    font-size: 36px;
    font-weight: 600;
}

.title4 {
    font-size: 26px;
    font-weight: 600;
}

.title5 {
    font-size: 20px;
    font-weight: 600;
}

.title6 {
    font-size: 18px;
    font-weight: 600;
}

.subTitle1 {
    font-size: 16px;
    font-weight: 500;
}

.body1 {
    font-size: 22px;
    line-height: 160%;
}

.body1-5 {
    font-size: 20px;
}

.body2 {
    font-size: 18px;
}

.body3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 160%;
}

.body4 {
    font-size: 14px;
    font-weight: 500;
}

@media (max-width:768px) {
    .title2 {
        font-size: 36px;
        margin-bottom: 20px;
    }

    .title3 {
        font-size: 30px;
    }
}

@media (max-width: 576px) {

    .body1,
    .body1-5,
    .body2,
    .body3,
    .body4 {
        word-break: break-all;
    }

    .title1 {
        font-size: 32px;
        font-weight: 800;
        margin-bottom: 20px;
    }

    .title2 {
        font-size: 28px;
        margin-bottom: 20px;
    }

    .title3 {
        font-size: 24px;
    }

    .title4 {
        font-size: 17px;
        /* word-break: break-all; */
    }

    .title5 {
        font-size: 18px;
    }

    .title6 {
        font-size: 16px;
    }

    .body1 {
        font-size: 16px;
    }

    .body1-5 {
        font-size: 15px;
    }

    .body2 {
        font-size: 15px;
    }

    .body3 {
        font-size: 12px;
    }

    .body4 {
        font-size: 10px;
    }
}

.sky {
    color: #10A4B3;
}

.white {
    color: white;
}

.dusk {
    color: #0B2B39;
}

.ocean {
    color: #0080A9;
}

.deep-sea {
    color: #051A27;
}

.ice {
    color: #F0F6FF;
}

.raspberry {
    color: #C91268;
}

.carrot {
    color: #F5911E;
}

.blueberry {
    color: #0019FE;
}

.lime {
    color: #C6FF3A;
}

.alignCenter {
    text-align: center;
}

.mb-1 {
    margin-bottom: 10px;
}

.mb-2 {
    margin-bottom: 2px;
}

.textShadow {
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
}


.gradient-sky-deepSea {
    background: linear-gradient(to right, #10A4B3 0%, #051A27 130%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    /* Fallback for browsers that do not support background-clip: text */
    -webkit-text-fill-color: transparent;
    /* Webkit browsers like Safari */
    display: inline-block;
}

.gradient-raspberry-carrot {
    background: linear-gradient(to bottom, #eb197b 20%, #F5911E 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    display: inline-block;
}

.gradient-sky-lime {
    background: linear-gradient(to bottom, #10A4B3 0%, #C6FF3A 120%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    display: inline-block;
}

.gradient-raspberry-sky {
    background: linear-gradient(to right, #C91268 0%, #2edef1 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    display: inline-block;
}

/* 다크 모드 */
@media (prefers-color-scheme: dark) {
    .gradient-sky-deepSea {
        background: linear-gradient(to right, #10A4B3 0%, #051A27 130%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        /* Fallback for browsers that do not support background-clip: text */
        -webkit-text-fill-color: transparent;
        /* Webkit browsers like Safari */
        display: inline-block;
    }

    .gradient-raspberry-carrot {
        background: linear-gradient(to bottom, #C91268 20%, #F5911E 100%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        -webkit-text-fill-color: transparent;
        display: inline-block;
    }

    .gradient-sky-lime {
        background: linear-gradient(to bottom, #10A4B3 0%, #C6FF3A 120%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        -webkit-text-fill-color: transparent;
        display: inline-block;
    }

    .gradient-raspberry-sky {
        background: linear-gradient(to right, #C91268 0%, #2edef1 100%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        -webkit-text-fill-color: transparent;
        display: inline-block;
    }
}