.streamingData-tit {
    margin-bottom: 30px;
}

.streamingData-text {
    margin-bottom: 20px;
}

.streamingData-text2 {
    margin-top: 20px;
    margin-bottom: 40px;
}

.img-compare-container {
    border-radius: 20px;
    overflow: hidden;
}

.streamingData-subTit {
    margin-bottom: 30px;
}

@media (max-width: 992px) {
    .streamingData-tit {
        font-size: 30px;
    }

    .streamingData-text,
    .streamingData-text2 {
        font-size: 18px;
        margin-bottom: 15px;
    }
}

@media (max-width: 576px) {
    .streamingData-tit {
        margin-bottom: 20px;
    }

    .__rcs-handle-button {
        width: 36px !important;
        height: 36px !important;
        opacity: 0.8;
        gap: 5px !important;
    }

    .__rcs-handle-button div {
        border-top: 5px solid transparent !important;
        border-right: 8px solid !important;
        border-bottom: 5px solid transparent !important;
    }
}

.iconCards-row {
    display: flex;
    align-items: stretch;
}

.useCases-a {
    text-align: right;
    color: #051A27;
    text-decoration: none;
}

.useCases-a p:hover {
    color: #10A4B3;
    transition: color 0.3s;
}