.scroll-top-btn {
    position: relative;
    align-items: center;
    background-color: #fff;
    border: none;
    box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.3);
}

.scroll-top-btn svg {
    font-size: 25px;
}

@media (max-width: 768px) {

    .scroll-top-btn svg {
        font-size: 20px;
    }
}

@media (max-width: 576px) {
    .scroll-top-btn {
        padding: 4px 6px;
        bottom: 2%;
        right: 3%;
    }
}