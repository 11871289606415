.button {
    padding: 10px 20px;
    border-radius: 45px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s;
}

@media (max-width: 768px) {
    .button {
        padding: 10px 10px;
    }
}

.primary {
    background-color: #10A4B3;
    color: white;
}
.primary:hover {
    background-color: #104eb3;
    color: white;
}

.secondary {
    background-color: #fff;
    color: #C91268;
}
.secondary:hover {
    background-color: #C91268;
    color: white;
}

.btnWhite {
    background-color: transparent;
    border: 2px solid white;
    color: white;
}
.btnWhite:hover {
    background-color: white;
    color: #0B2B39;
}

.btnBlueberry {
    background-image: linear-gradient(to left, #10A4B3, #10A4B3, #005299, #0B2B39);
    background-size: 300%;
    background-position: right;
    transition: background-position ease-in-out 300ms;
    color: white;
}
.btnBlueberry:hover {
    background-position: left;
}

.btnGradient-sky-lime {
    background-image: linear-gradient(to right, #10A4B3, #10A4B3, #10A4B3, #C6FF3A);
    background-size: 300%;
    background-position: left;
    transition: background-position ease-in-out 300ms;
    color: white;
}
.btnGradient-sky-lime:hover {
    background-position: right;
}