.carouselCard {
    /* height: 600px; */
    background-color: rgba(6, 25, 39, 0.8);
    margin: 3.7rem 0;
    border-radius: 15px;
}

.carousel-item {
    height: 600px;
}

.carousel-caption {
    top: 25%;
}

.carousel-title {
    /* text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.85); */
}

.carousel-content {
    /* font-size: 26px;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.85); */
    font-weight: normal;
    margin-bottom: 50px;
}

/* 캐러셀 인디케이터 커스텀 스타일 */
.carousel-indicators [data-bs-target] {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-left: 8px;
    margin-right: 8px;
}

.carousel-indicators .active {
    background-color: #10A4B3;
}

.carousel-button-container {
    display: flex;
    justify-content: center;
    gap: 3rem;
}

.carousel-button-container button {
    min-width: 220px;
}

@media (max-width: 992px) {
    .carousel-caption {
        top: 15%;
    }
}

@media (max-width: 768px) {
    .carousel-item {
        height: 500px;
    }
    .carousel-caption {
        /* padding-top: 1rem; */
        top: 5%;
    }
    .carousel-item-container {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    .carousel-title {
        margin-bottom: 15px;
    }
    .carousel-content {
        margin-bottom: 20px;
    }

    .carousel-button-container button {
        margin-right: 10px;
        font-size: 14px;
        min-width: 150px;
    }
}

@media (max-width: 576px) {
    .carousel-item {
        height: 400px;
    }

    .carousel-caption {
        top: 0;
        right: 8%;
        left: 8%;
    }

    

    .carousel-title {
        font-size: 25px;
    }

    .carousel-content {
        margin-bottom: 20px;
        font-size: 15px;
    }

    .carousel-button-container {
        gap: 0;
        /* position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%); */
    }

    .carousel-button-container button {
        margin-right: 6px;
        font-size: 10px;
        min-width: 120px;
    }

    .carousel-control-prev,
    .carousel-control-next {
        width: 9%;
    }

    .carousel-indicators [data-bs-target] {
        width: 13px;
        height: 13px;
    }
}