.carouselCard-base {
    padding-top: 20px;
}

.carouselCard-body {
    height: 430px;
}
.carouselCard-body p {
    text-align: center;
}

.global-clients-container {
    border-bottom: 2px solid rgb(255, 255, 255, 0.5);
    margin-bottom: 20px;
}

@media (max-width: 576px) {
    .carouselCard-body {
        height: 400px;
    }
    .carouselCard-body .title2 {
        font-size: 24px;
    }
}