.milestones-section {
    background-image: url('../assets/images/DALL·E\ 2024-03-25\ 10.34.04.png');
    background-size: cover;
    background-position: top center;
}
.milestones-section-text {
    margin-bottom: 3.5rem;
}

.milestones {
    
}
.milestones-tit {
    margin-bottom: 20px;
}

@media (max-width: 992px) {
    .milestones-section-tit {
        font-size: 30px;
    }
    .milestones-section-text {
        font-size: 18px;
        margin-bottom: 2.5rem;
    }
    .milestones {
        
    }
}
