.customerCard-base {
    padding-top: 20px;
}

.customerCard-body {
    /* height: 430px; */
}

.carouselCard-body p {
    /* text-align: center; */
}
.customerObsession-subTit {
    margin-bottom: 4rem;
}

.customerObsession-carousel-container:first-of-type {
    border-bottom: 2px solid rgb(5, 26, 39, 0.1);
    margin-bottom: 20px;
}

.customerObsession-carousel-container .carousel-card {
    border: 2px solid rgb(5, 26, 39, 0.2);
}