.myTab-nav {
    height: auto;
}
.myTab-row {
    border: 2px solid rgba(6, 25, 39, 0.4);
    border-radius: 20px;
    overflow: hidden;
}
.myTab-col-nav {
    background-color: #ececec;
    padding: 30px 0;
}
.myTab-nav-item {
    margin: 0 20px;
}
@media (max-width: 992px) {
    .myTab-nav-item {
        margin: 0 10px;
    }
}
.myTab-nav-item .nav-link {
    border: 2px solid transparent;
    color: #003344;
}
.myTab-nav-item .nav-link,
.myTab-nav-item .nav-link:hover,
.myTab-nav-item .nav-link.active {
    border-radius: 45px;
}
.myTab-nav-item .nav-link.active {
    background-color: #0080A9;
}
.myTab-nav-item .nav-link.active:hover {
    color: #ffffff;
}
.myTab-nav-item .nav-link:hover {
    color: #0080A9;
    border: 2px solid #0080A9;
}
.myTab-nav-item + .myTab-nav-item {
    margin-top: 10px;
}
.myTab-nav .nav-item {
    text-align: center;
}


.myTab-col-content {
    background-color: #fff;
    padding: 3.7rem 3.4rem;
}
.myTab-content-tit {
    margin-bottom: 30px;
}
.myTab-content-par + .myTab-content-par {
    margin-top: 15px;
}
.myTab-content-list-tit {
    margin-top: 20px;
}
.myTab-content-list-text {
    margin-left: 28px;
}
@media (max-width: 576px) {
    .myTab-col-content {
        padding: 30px 20px;
    }
}
@media (max-width: 300px) {
    .myTab-col-content {
        padding: 25px 12px;
    }
}