.streamline-section-col {
  display: flex;
  align-items: center;
}
.streamline-section-col-img .card-img-top {
  height: 140px;
}
.streamline-section-col-img .title5 {
  font-size: 16px;
  font-weight: normal;
}
.streamline-section-col-img .body2 {
  font-weight: 600;
}
.streamline-section-col-img .card-body {
  padding-bottom: 20px;
}
.streamline-section-text-body .title4 {
  margin-bottom: 15px;
}
.streamline-section-imgCard {
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}
