.icon-card {
    border: none;
    border-radius: 20px;
    height: 100%;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}
.icon-card:hover {
    transform: scale(1.05);
    transition: transform 0.4s ease-in-out;
}
.icon-container {
    width: 65px;
    height: 100px;
    object-fit: contain;
}