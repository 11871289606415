html,
body {
  font-family: "Roboto", sans-serif;
}

.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* 모바일에서 AOS 비활성화 */
@media (max-width: 576px) {
  [data-aos] {
    pointer-events: auto !important;
    opacity: 1 !important;
    transform: none !important;
  }
}

/* navigation */
.nav {
  height: 63px;
}

.nav ul {
  margin-bottom: 0%;
}

.nav-list li {
  list-style-type: none;
  height: 100%;
  float: left;
  text-align: center;
  align-items: center;
}

.nav-list>li {
  position: relative;
  display: flex;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}

.nav-list>li.hover-underline:hover {
  color: #10a4b3;
}

.nav-list a {
  text-decoration: none;
  color: black;
}

.nav-list a:hover {
  color: #10a4b3;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  text-align: center;
  padding-left: 0;
  background-color: #fff;
}

.dropdown-content li {
  display: block;
  /* width: 200px;     */
}

.dropdown-content>li {
  margin: 10% 0 10% 0;
}

.dropdown-content li.sub-level {
  color: black;
}

.dropdown-content li.sub-level:hover {
  color: black;
}

/* navigation div 버전 */
.dropdown-container {
  position: absolute;
  width: 100%;
  /* left: auto; */
  /* padding-left: 1rem; */
  /* justify-content: flex-end; */
  margin-left: 1rem;
  z-index: 100;
}

.dropdown-container a {
  text-decoration: none;
  color: black;
}

.dropdown-container li {
  list-style-type: none;
}

.dropdowns {
  background-color: rgba(150, 150, 150, 0.744);
}

/* 밑줄 스타일 */
.hover-underline::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: 0;
  border-bottom: 6px solid #10a4b3;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.nav-list li.active::after {
  /* 활성화된 링크의 밑줄 스타일 */
  left: 0;
  right: 0;
  transform: scaleX(1);
}

/* 호버 상태에서 밑줄 애니메이션 */
.hover-underline:hover::after {
  left: 0;
  right: 0;
  transform: scaleX(1);
}

/* main element의 기본 스타일 */
main {
  position: relative; /* 새로운 스택 컨텍스트를 생성 */
  padding-top: 63px; /* Header의 높이 */
  flex-grow: 1;
}

/* 배경 이미지를 위한 새로운 div */
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white url("../assets/images/DALL·E-2024-01-22-14.27.webp") top center no-repeat;
  z-index: -1; /* 컨텐츠 아래로 */
}

/* 다크 오버레이 적용되는 조건에 따라 적용할 클래스 */
.dark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: -1; /* 배경 이미지 바로 위 */
}
/* 에러 페이지용 흰 배경 */
.white-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0B2B39;
  z-index: -1;
}

@media (min-width: 1500px) {
  .background-image {
    background-size: contain;
  }
}

main {
  flex-grow: 1;
}

.scrollBtn-container {
  bottom: 1%;
  right: 1%;
  position: fixed;
  width: 40px;
  height: 59px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 576px) {
  .scrollBtn-container {
    bottom: 2%;
    right: 3%;
    width: 32px;
    height: 65px;
  }
}