.error-sec-card {
    margin-top: 3.7rem;
    text-align: center;
}
.error-sec-card .title3 {
    margin-bottom: 1.2rem;
}

.error-sec-link {
    /* text-decoration: none; */
    color: black;
}
@media (hover:hover){
    .error-sec-link:hover {
        color: #0080A9;
        text-decoration: underline;
    }
}
