/* 헤더 */
header {
    box-shadow: 0px 4px 6px -4px rgb(0, 0, 0, 0.25);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: #fff;
}

@media (max-width: 992px) {
    header .col-1 {
        padding: 0;
    }
}

@media (max-width: 768px) {
    header>.container {
        max-width: 100%;
    }
}

@media (max-width: 576px) {
    header>.container {
        padding-right: 1.2rem;
    }
}

.mobile-nav-icon {
    border: none;
    background-color: transparent;
    font-size: 30px;
}

/* 네비게이션 */
/* .nav-underline {
    height: 63px;
    --bs-nav-underline-gap: 0;
    font-size: 16px;
    line-height: 36px;
    position: relative;
}

.nav-underline .nav-link {
    position: relative;
    color: #000000;
    padding-left: 1rem;
    padding-right: 1rem;
    border: 0;
    text-decoration: none;
}

.nav-underline .nav-link:hover,
.nav-underline .nav-link:focus {
    color: #10A4B3;
}

.hover-underline {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 6px;
    background-color: #10A4B3;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

a:hover .hover-underline {
    transform: scaleX(1);
} */

.mobile-nav {
    position: fixed;
    /* top: 0; */
    right: 0;
    width: 100%;
    /* height: 100%; */
    background-color: rgba(233, 233, 233, 0.89);
    transform: translateX(100%);
    transition: transform 2s ease-in-out;
    z-index: 20;
}

.mobile-nav.active {
    transform: translateX(0);
}

.mobile-menu ul {
    list-style-type: none;
    padding: 20px;
}

.mobile-menu li {
    padding: 10px 0;
}

.mobile-menu a {
    color: black;
    text-decoration: none;
}

.mobile-menu .active {
    color: #10A4B3;
}
.mobile-menu .active:hover {
    cursor: default;
}

.mobile-overlay {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(39, 39, 39, 0.644);
    z-index: 10;
    /* 헤더의 z-index보다 낮게 설정 */
}

@media (prefers-color-scheme: dark) {
    header {
        background-color: #fff;
    }
}