.flip-card {
    border: none;
    border-radius: 20px;
    height: 100%;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.card-flip-front {
    text-align: center;
}

.flip-icon-container {
    width: 65px;
    height: 100px;
    object-fit: contain;
}

.card-flip {
    perspective: 1000px;
    height: 100%;
    min-height: 200px;
}

.card-flip-inner {
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    position: relative;
}

@media(hover: hover) and (pointer: fine) {
    .card-flip:hover .card-flip-inner {
        transform: rotateY(180deg);
    }
}
.card-flip-inner.is-flipped {
    transform: rotateY(180deg);
}
.card-flip-front,
.card-flip-back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.card-flip-back {
    transform: rotateY(180deg);
}

.flipCard-body {
    width: 100%;
}
.flipCard-body-back {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}
.flip-card-ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
}
.flip-card-list-icon {
    font-size: 18px;
    margin-right: 8px;
    margin-top: 4px;
}
.flip-card-list {
    display: flex;
    align-items: flex-start;
    margin-bottom: 5px;
}
@media (max-width: 576px) {
    .flip-card-list-icon {
        margin-top: 0;
    }
}