.myDropdown {
    
}
.myDropdown-btn {
    width: 100%;
    display: flex;
    background-color: #fff;
    color: rgb(90, 90, 90);
    border: none;
}
.myDropdown-btn:hover,
.myDropdown-btn:not(:disabled):not(.disabled):active,
.myDropdown-btn:not(:disabled):not(.disabled).active,
.show>.myDropdown-btn.dropdown-toggle {
    background-color: #fff;
    color: rgb(90, 90, 90);
    border: none;
}
.myDropdown-btn::after {
    display: none;
}
.myDropdown-menu {
    width: 100%;
}