.official-partner-section {
    background: linear-gradient(to bottom, rgba(31, 78, 120), white 40%);
}

.partner-logo {
    display: flex;
    width: 100%;
    height: 100%;
}

.partner-logo img {
    height: 90px;
    margin: auto;
}

.official-partner-btn-container {
    text-align: center;
}

@media (max-width: 576px) {
    .partner-logo img {
        height: 70px;
    }
}

/* @media (prefers-color-scheme: dark) {
    .official-partner-section {
        background: linear-gradient(to bottom, rgb(22, 56, 87), rgb(154, 154, 172) 40%);
    }
} */

.official-partner-text {
    text-indent: 5px;
}