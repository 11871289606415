.twoThirdSection-card-body {
    min-height: 270px;
}
.twoThirdSection-card-body .row {
    height: 100%;
    min-height: 270px;
}
.twoThirdSection-card-body .col {
    /* min-height: 300px; */
    height: 100%;
    min-height: 270px;
}
.twoThird-text {
    display: flex;
    align-items: center;
}