.carousel-container {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 120px;
    padding-bottom: 20px;
}

.carousel-card {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;

    /* &:hover {
        transform: scale(1.1);
        box-shadow: 5px 5px 8px rgba(222, 222, 222, 0.362),
            10px 10px 8px rgba(152, 133, 133, 0.392),
            15px 15px 8px rgba(139, 154, 139, 0.303);
    } */

    border-radius: 15px;
    background: rgb(255, 255, 255);
}

/* 다크 모드 */
@media (prefers-color-scheme: dark) {
    .carousel-card {
        background: rgb(255, 255, 255);
    }
}

.carousel-card>img {
    height: 90px;
    width: 180px;
    border-radius: 15px;
    object-fit: contain;
}

.rfm-child {
    margin-right: 20px;
}

.carousel-track {
    display: flex;
    position: absolute;
    left: 0;
    justify-content: center;
    align-items: center;
    gap: 26px;
    width: 200%;
    height: auto;
    animation: slide 20s linear infinite;

    &:hover {
        animation-play-state: paused;
    }
}

@keyframes slide {
    0% {
        left: 0;
    }

    100% {
        left: -100%;
    }
}