.unifiedPlatform-section-tit {
    text-align: center;
    margin-bottom: 30px;
}
.unifiedPlatform-img-container {
    max-width: 550px;
    border-radius: 20px;
    overflow: hidden;
    margin: auto;
    margin-bottom: 35px;
}
@media (max-width: 576px) {
    .unifiedPlatform-section-tit {
        margin-bottom: 20px;
    }
}