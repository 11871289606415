.enhance-section-col-img {
    display: flex;
}
.enhance-section-col-img img {
    object-fit: contain;
}
.enhance-section-text-body .title4 {
    margin-bottom: 15px;
}
.enhance-section-col {
    display: flex;
    align-items: center;
}