.solution-sec-link-row .col {
    display: flex;
    justify-content: end;
}

.solution-sec-link {
    text-decoration: none;
    color: #fff;
}

.solution-sec-link:hover {
    color: #10A4B3;
}

.solution-section-baseCard .row+.row {
    margin-top: 2.4rem;
}

@media (max-width: 1200px) {
    .solution-section-baseCard .col-xl-4+.col-xl-4 {
        margin-top: 2.4rem;
    }
}

.filter-form-container {
    margin-bottom: 2.7rem;
}

.filter-conditions-container {
    margin: 1.4rem 0;
}

@media (max-width: 768px) {
    .solution-section-baseCard .row+.row {
        margin-top: 1.2rem;
    }

    .filter-form-container {
        margin-bottom: 1.7rem;
    }
}

.solution-section-pagination-col {
    display: flex;
    justify-content: flex-end;
}

.solution-section-pagination {
    background-color: transparent;
}

/* 모든 페이징 버튼의 기본 스타일 */
.solution-section-pagination .page-link {
    color: white;
    background-color: transparent;
    border: 2px solid transparent;
    border-radius: 45px !important;
}

/* 현재 선택된 페이지 버튼 스타일 */
.solution-section-pagination .page-item.active .page-link {
    color: #fff;
    background-color: #10A4B3;
    border-color: #10A4B3;
}

/* 호버 효과 */
.solution-section-pagination .page-item:not(.active):hover .page-link {
    background-color: transparent;
    color: #10A4B3;
    border-color: #10A4B3;
}

/* 비활성화된 페이지 버튼 스타일 */
.solution-section-pagination .page-item.disabled .page-link {
    color: #999;
}