.image-card {
    border-radius: 20px;
    overflow: hidden;
    /* min-height: 460px; */
    position: relative;
    flex: 1;    
}

.card-img-top {
    object-fit: cover;
}

.image-card:hover {
    transform: scale(1.05);
    transition: transform 0.4s ease-in-out;
}
.img-card-clickable:hover {
    cursor: pointer;
}

.image-card>.card-body {
    padding: 16px 30px;
    padding-bottom: 70px;
}

.imgCard-button-container {
    position: absolute;
    bottom: 16px;
}

/* 화면 크기가 768px 이하일 때 적용할 스타일 */
@media (max-width: 992px) {
    .imgCard-body .title5 {
        font-size: 16px;
    }
}
@media (max-width: 576px) {
    .image-card>.card-body {
        padding: 16px 13px;
        padding-bottom: 70px;
    }
}