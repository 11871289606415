.available-platform-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.platform-logo {
    display: flex;
    width: 362px;
    height: 56px;
    
}
.platform-logo img {
    flex: 1;
    object-fit: contain;
    height: 48px;
}

@media (max-width: 992px) {
    .platform-logo img {
        height: 34px;
    }
}
@media (max-width: 576px) {
    .available-platform-container {
        display: block;
        width: 100%;
        text-align: center;
    }
    .platform-logo {
        width: 100%;
    }
    .platform-logo img {
        height: 28px;
        
    }
}