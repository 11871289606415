.ourValueSection-tit {
    margin-bottom: 30px;
}

.ourValueSection-text-container {
    display: flex;
    align-items: center;
}

@media (max-width: 992px) {
    .ourValueSection-text-container p {}
}