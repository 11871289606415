.realtime-tit {
    /* margin-bottom: 30px; */
}

.realtime-text-container {
    padding-top: 35px;
}
.realtime-text-container p:not(:last-child) {
    margin-bottom: 8px;
}

@media (max-width: 576px) {
    .react-player-container>div {
        /* height: auto; */
    }
}