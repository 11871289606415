.searchBar-form {
    width: 100%;
    position: relative;
}
input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
}
.searchBar-form-control::placeholder {
    font-size: 18px;
    color: rgb(90, 90, 90);
}
.searchBar-form-control {
    height: 39px;
}
.searchBar-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}
.searchBar-close {
    right: 30px;
}