.simplifyArchitecture-section-tit {
    margin-bottom: 30px;
}

.simplify-img-compare-container {
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 30px;
}
@media (max-width: 576px) {
    .simplifyArchitecture-section-tit {
        margin-bottom: 20px;
    }
}