.large-card,
.small-card {
    margin-bottom: 20px;
    border-radius: 20px;
}

.large-card {
    min-height: 440px;
}

@media (max-width: 1200px) {
    .large-card {
        min-height: fit-content;
    }

    .large-card .title4 {
        font-size: 22px;
    }
}

.large-card a,
.small-card a {
    text-decoration: none;
    color: #0B2B39;
}

.large-card .title4 {
    margin-bottom: 10px;
}

.large-card .body2 {
    margin-bottom: 10px;
}

.large-card-first {
    background-image: url(../../assets/images/DALL·E-2024-03-11-11.44.webp);
    background-size: cover;
    background-position: center;
    color: white;
}

.small-card .title5 {
    margin-bottom: 5px;
}

.small-card-1 {
    background-color: #F0F6FF;
    color: #0B2B39;
}

.small-card-2 {
    background-color: #0B2B39;
    color: white;
}

.small-card-2 a {
    color: #C6FF3A;
}

.small-card-1 a:hover,
.small-card-2 a:hover,
.large-card a:hover {
    color: #10A4B3;
    font-weight: 600;
}

.large-card-second {
    background-color: #F0F6FF;
}

.large-card-second .firstResource-title {
    background: linear-gradient(to bottom, #C91268 0%, #F5911E 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    display: inline-block;
}

.large-card-second .secondResource-title {
    /* color: #10A4B3; */
}

/* @media (prefers-color-scheme: dark) {
    .large-card-second .firstResource-title {
        background: linear-gradient(to bottom, #f3539e 20%, #f8bb74 100%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        -webkit-text-fill-color: transparent;
        display: inline-block;
    }
} */