.useCaseCard-container {
    display: flex;
    justify-content: space-around; /* 또는 space-between */
    align-items: center; 
    /* flex-wrap: wrap; 반응형을 위해 여러 줄로 나누기 */
}

.useCase-row {
    align-items: stretch;
}

.useCase-col {
    display: flex;
}
.useCase-div {
    display: flex;
}

.useCase-col .image-card {
    /* min-height: 460px; */
}
.useCase-col .card-img-top {
    height: 180px;
}

@media (max-width: 576px) {
    .useCase-col .card-img-top {
        height: 120px;
    }
}

