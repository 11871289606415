.platformIntro-subtit {
    margin-bottom: 20px;
}

.platformIntro-text-container {
    padding-top: 2rem;
}

.platformIntro-text {
    /* word-break: keep-all; */
}

@media (max-width: 992px) {
    .platformIntro-subtit {
        font-size: 30px;
    }

    .platformIntro-text-container {
        padding-top: 0.5rem;
    }
}
@media (max-width: 576px) {
    .platformIntro-subtit {
        margin-bottom: 20px;
    }
    
}